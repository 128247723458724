import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
export const inview = () => {
    const getElsToEnter = [
        document.querySelector('.header__h1'),
        document.querySelector('.header__subtitle'),
        document.querySelector('.korean-heart__hand'),
        document.querySelector('.korean-heart__waves'),
        document.querySelector('.korean-heart__luminance'),
        document.querySelector('.description__title'),
        document.querySelector('.description__text'),
        document.querySelector('.description__icon'),
        document.querySelectorAll('.block-1__title'),
        document.querySelectorAll('.block-1__text'),
        document.querySelectorAll('.block-2__item'),
        document.querySelector('.block-3__title'),
        document.querySelector('.block-3__list')
    ];

    const elementsToEnter = getElsToEnter;
    const enterClass = 'entered';

    const btnsArrow = document.querySelectorAll('.button-arrow');
    // const header = document.querySelector('.header');
    const main = document.querySelector('.main');

    btnsArrow.forEach((btn) => {
        btn.onclick = () => {
            main.scrollIntoView({ behavior: 'smooth' });
        };
    });
    const addScrollTriggers = (els) => {
        if (!els?.length) return;

        const elements = gsap.utils.toArray(els);

        console.log('elements', elements);
        elements.forEach((el) => {
            ScrollTrigger.create({
                trigger: el,
                start: 'top 99%',
                end: 'top 99%',
                once: true,
                onEnter: () => {
                    el.classList.add(enterClass);
                }
            });
        });
    };

    addScrollTriggers(elementsToEnter);

};
